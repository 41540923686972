import { Component, OnInit } from '@angular/core';
import {Kaartitem} from '../model/kaartitem';
import {MenuService} from '../services/menu.service';

@Component({
  selector: 'app-aperitiefhapjes',
  templateUrl: './aperitiefhapjes.component.html'
})
export class AperitiefhapjesComponent implements OnInit {
  aperitiefAmu!: Kaartitem[];
  constructor(private menuService: MenuService) { }

  ngOnInit(): void {
    this.menuService.getAperitiefAmu()
      .subscribe((aperitiefAmu: Kaartitem[]) => {
        this.aperitiefAmu = aperitiefAmu;
      });
  }

}
