import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MenuComponent } from './menu/menu.component';
import { DrankenComponent } from './dranken/dranken.component';
import { ReserverenComponent } from './reserveren/reserveren.component';
import { GreedymondayComponent } from './greedymonday/greedymonday.component';
import { ImpressiesComponent } from './impressies/impressies.component';
import { TakewayComponent } from './takeway/takeway.component';
import { NaviagationComponent } from './naviagation/naviagation.component';
import { DranknavigatieComponent } from './dranken/dranknavigatie/dranknavigatie.component';
import { AperitievenComponent } from './dranken/aperitieven/aperitieven.component';
import { WijnsuggestieComponent } from './dranken/wijnsuggestie/wijnsuggestie.component';
import { WijnkaartComponent } from './dranken/wijnkaart/wijnkaart.component';
import { FrisdrankenComponent } from './dranken/frisdranken/frisdranken.component';
import { WarmedrankenComponent } from './dranken/warmedranken/warmedranken.component';
import { DigestievenComponent } from './dranken/digestieven/digestieven.component';
import { KaartItemComponent } from './kaart-item/kaart-item.component';
import { DessertwijnenComponent } from './dranken/dessertwijnen/dessertwijnen.component';
import { WijnKaartItemComponent } from './wijn-kaart-item/wijn-kaart-item.component';
import { WhiskeyComponent } from './dranken/whiskey/whiskey.component';
import { GinComponent } from './dranken/gin/gin.component';
import { ParkingComponent } from './parking/parking.component';
import { GeslotenComponent } from './gesloten/gesloten.component';
import { AperitiefhapjesComponent } from './aperitiefhapjes/aperitiefhapjes.component';
import { WijnKaartItem2Component } from './wijn-kaart-item2/wijn-kaart-item2.component';
import { HttpClientModule } from '@angular/common/http';
import { ProductComponent } from './product/product.component';
import { BierSuggestiesComponent } from './dranken/bier-suggesties/bier-suggesties.component';
import { KaartItemDrankComponent } from './dranken/kaart-item-drank/kaart-item-drank.component';
import { MineraleComponent } from './minerale/minerale.component';

@NgModule({
  declarations: [
    AppComponent,
    AppComponent,
    HomeComponent,
    MenuComponent,
    DrankenComponent,
    ReserverenComponent,
    GreedymondayComponent,
    ImpressiesComponent,
    TakewayComponent,
    NaviagationComponent,
    DranknavigatieComponent,
    AperitievenComponent,
    WijnsuggestieComponent,
    WijnkaartComponent,
    FrisdrankenComponent,
    WarmedrankenComponent,
    DigestievenComponent,
    KaartItemComponent,
    DessertwijnenComponent,
    WijnKaartItemComponent,
    WhiskeyComponent,
    GinComponent,
    ParkingComponent,
    GeslotenComponent,
    AperitiefhapjesComponent,
    WijnKaartItem2Component,
    ProductComponent,
    BierSuggestiesComponent,
    KaartItemDrankComponent,
    MineraleComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
