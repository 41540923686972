
<div class="topnav" id="myTopnav">
  <a routerLink="/home" (click)="onClick()">Home</a>
  <a routerLink="/menu" (click)="onClick()">Menu</a>


  <div class="dropdown">
    <button class="dropbtn">Dranken
      <i class="fa fa-caret-down"></i>
    </button>
    <div class="dropdown-content">
<!--      <a routerLink="/minerale" (click)="onClick()">Tournée Minérale</a>-->
      <a routerLink="/aperitief" (click)="onClick()">Aperitief - Stoere bubbels - Fris</a>
      <a routerLink="/gin" (click)="onClick()">Gin</a>
      <a routerLink="/wijnsuggestie" (click)="onClick()">Wijn suggesties</a>
<!--      <a routerLink="/biersuggestie" (click)="onClick()">Bier suggesties</a>-->
      <a routerLink="/wijnkaart" (click)="onClick()">Wijnkaart</a>
      <a routerLink="/digestieven" (click)="onClick()">Digestieven</a>
      <a routerLink="/warmedranken" (click)="onClick()">Warme dranken</a>
      <a routerLink="/whisky" (click)="onClick()">Whisky</a>

    </div>
  </div>

  <a routerLink="/reserveren" (click)="onClick()">Reserveren</a>
<!--  <a routerLink="/impressies" (click)="onClick()">Actie: De smaak - Bier</a>-->
 <a routerLink="/takeaway" (click)="onClick()">Summer Take Away</a>



  <a href="javascript:void(0);" style="font-size:15px;" class="icon" onclick="myFunction()">&#9776;</a>
</div>



