<div class="box">
  <div class="title">Reserveren</div>

  <div class="content">

    <p>
      Kom genieten van onze heerlijke Amukes, dit kan zowel aan onze food bar of in onze lounge. <br/>
      Uiteraard hebben we ook nog steeds enkele tafels ter beschikking dus voor iedereen wat wils.
    </p>


    <p>
      Openingsuren<br/>
    </p>
    <div style="text-align: left">
      <table style="margin-left: auto; margin-right: auto">
        <tr>
          <td>Maandag</td>
          <td>Lunch: 12u.</td>
          <td>Diner: 18u.</td>
        </tr>
        <tr>
          <td>Donderdag</td>
          <td></td>
          <td>Diner: 18u.</td>
        </tr>
        <tr>
          <td>Vrijdag</td>
          <td>Lunch: 12u.</td>
          <td>Diner: 18u.</td>
        </tr>
        <tr>
          <td>Zaterdag</td>
          <td></td>
          <td>Diner: 18u.</td>
        </tr>
        <tr>
          <td>Zondag</td>
          <td>Lunch: 12u.</td>
          <td>Diner: 17u30</td>
        </tr>
      </table>
    </div>
    <p>
      Dinsdag en Woensdag gesloten. <br/>
    </p>


    <p>
      Reserveren is aangeraden en kan via telefoon: 014/41.24.89 of 0486/32.05.21 <br/>of via email <a
      href="mailto: info@amu-turnhout.be">info@amu-turnhout.be</a>
    </p>

    <p>
      Amu <br/>
      Patersstraat 79 - 81<br/>
      2300 Turnhout<br/>
      <br/>
      BTW: BE0785.889.842
    </p>
    <p>
      <strong>
        Ons verhaal is culinair compleet, waardoor er geen wijzigingen of aanpassingen
        mogelijk zijn, zijn er levensbedreigende allergenen ( schaal, schelp, noten,… )
        gelieve dit bij uw reservatie te melden, zodat we uw aanpassingen kunnen
        garanderen. Indien u het aantal gasten van uw reservatie wenst te wijzigen,
        gelieve dit vooraf te melden, indien niet vooraf gemeld zijn we genoodzaakt om
        hiervoor een vergoeding aan te rekenen ter waarde van ons Bib Gourmand
        menu.
      </strong>
    </p>

    <p>
      Wenst u graag te reserveren aan de bar, in onze lounge of aan een tafel geef dit alvast door bij uw
      reservatie zodat we indien mogelijk uw plaats kunnen garanderen.
    </p>

    <!--    <ng-image-slider [images]="imageObject" #nav></ng-image-slider>-->

  </div>


</div>

<app-parking></app-parking>





